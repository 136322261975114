<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="createModelDialog"
      max-width="700"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ modelEditTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editModel.name"
                  label="Model Name *"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editModel.generation"
                  label="Generation *"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editModel.brand"
                  label="Brand *"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editModel.description"
                  label="Description"
                  rows="3"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeModelEditWindow"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveEdit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDeleteDialog"
      max-width="700"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want delete this model {{ editModel.name }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="resetForm"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Equipment Mode Management"
      color="accent"
    >
      <snack-bar ref="snackBar" />
      <v-data-table
        :items="equipmentModelList"
        :headers="equipmentModelHeader"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Model List</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-spacer />
            <v-btn
              class="ma-2"
              @click="createModelDialog = true"
            >
              Create New
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editSelect(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="deleteSelect(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
  import modelApi from '@/api/model'
  import SnackBar from '@/components/app/SnackBar'
  import store from '@/store'
  export default {
    name: 'EquipmentModel',
    components: { SnackBar },
    data () {
      return {
        createModelDialog: false,
        equipmentModelList: [],
        equipmentModelHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'name', value: 'name', sortable: true },
          { text: 'generation', value: 'generation', sortable: true },
          { text: 'brand', value: 'brand', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        modelIndex: -1,
        defaultModel: {
          name: '',
          generation: '',
          brand: '',
          description: '',
        },
        editModel: {
          name: '',
          generation: '',
          brand: '',
          description: '',
        },
        confirmDeleteDialog: false,
      }
    },
    computed: {
      modelEditTitle () {
        return this.modelIndex > -1 ? 'Edit Equipment\'s Model' + this.editModel.name : 'Create Equipment\'s Model'
      },
    },
    mounted () {
      this.loadModelList()
    },
    methods: {
      loadModelList () {
        modelApi.index().then(response => {
          this.equipmentModelList = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadModel (id) {
        modelApi.show(id).then(response => {
          if (response.data.code === 200) {
            this.editModel = response.data.data
            this.createModelDialog = true
          } else {
            store.set('message/storeMessage', {
              color: 'error',
              text: 'Something wrong, we cant load you necessary information right now',
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      editSelect (item) {
        this.modelIndex = item.id
        this.loadModel(this.modelIndex)
      },
      deleteSelect (item) {
        this.modelIndex = item.id
        this.editModel = item
        this.confirmDeleteDialog = true
      },
      confirmDelete () {
        this.confirmDeleteDialog = false
        modelApi.destroy(this.modelIndex).then(response => {
          if (response.data.code === 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          } else {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }).finally(() => {
          this.resetForm()
          this.loadModelList()
        })
      },
      saveEdit () {
        this.createModelDialog = false
        if (this.modelIndex > -1) {
          this.updateModel()
        } else {
          this.createModel()
        }
      },
      createModel () {
        modelApi.store(this.editModel).then(response => {
          if (response.data.code === 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          } else {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }).finally(() => {
          this.resetForm()
          this.loadModelList()
        })
      },
      updateModel () {
        modelApi.update(this.editModel, this.modelIndex).then(response => {
          if (response.data.code === 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          } else {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }).finally(() => {
          this.resetForm()
          this.loadModelList()
        })
      },
      closeModelEditWindow () {
        this.createModelDialog = false
        this.resetForm()
      },
      resetForm () {
        this.confirmDeleteDialog = false
        this.modelIndex = -1
        this.editModel = Object.assign({}, this.defaultModel)
      },
    },
  }
</script>

<style scoped>

</style>
